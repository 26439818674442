import { forwardRef } from "react"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "../Pagination"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Select"
import { useTranslation } from "react-i18next"

const PAGE_SIZES = [5, 10, 20, 50]

type TablePaginationProps = {
  page: number
  pageSize: number
  numRows: number
  hasNextPage: boolean
  hasPrevPage: boolean
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

const TablePagination = forwardRef<HTMLDivElement, TablePaginationProps>(
  (
    {
      page,
      pageSize,
      numRows,
      hasNextPage,
      hasPrevPage,
      onPageChange,
      onPageSizeChange,
    },
    ref,
  ) => {
    const { t } = useTranslation("common")
    const from = Math.max((page - 1) * pageSize, 1)
    const to = Math.min(page * pageSize, numRows)

    return (
      <Pagination ref={ref} isSimple>
        <PaginationContent className="gap-6">
          <div className="flex flex-row items-center gap-2">
            <p className="text-sm">Rows per page</p>
            <Select
              value={pageSize.toString()}
              onValueChange={(size) => onPageSizeChange(Number(size))}
            >
              <SelectTrigger className="h-8 w-fit">
                <SelectValue placeholder="5" />
              </SelectTrigger>
              <SelectContent>
                {PAGE_SIZES.map((size) => (
                  <SelectItem key={size} value={size.toString()}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-row items-center gap-2">
            <p className="text-sm">{`${from} - ${to} ${t("of")} ${numRows}`}</p>
            <PaginationItem onClick={() => onPageChange(page - 1)}>
              <PaginationPrevious disabled={!hasPrevPage} isSimple />
            </PaginationItem>
            <PaginationItem onClick={() => onPageChange(page + 1)}>
              <PaginationNext disabled={!hasNextPage} isSimple />
            </PaginationItem>
          </div>
        </PaginationContent>
      </Pagination>
    )
  },
)

TablePagination.displayName = "TablePagination"

export { TablePagination }
